<template>
    <div class="header">
        
        <div class="header__logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 138.18 52.91">
                <g :style="{'fill': theme}">
                    <path d="M86.7,30.2l2.6,0l0,22.3l-2.6,0L86.7,30.2z M45.5,21.3l-1.7,0l0-16.7l1.7,0l0,2.4c0.9-1.6,2.4-2.6,4.4-2.6
	c3.1,0,5.4,2.5,5.4,6c0,3.5-2.3,6-5.4,6c-2,0-3.5-1-4.4-2.5L45.5,21.3z M45.5,10.3c0,2.5,1.8,4.3,4.1,4.3c2.3,0,3.9-1.8,3.9-4.3
	c0-2.5-1.6-4.3-3.9-4.3C47.2,6,45.5,7.8,45.5,10.3z M124.6,12.1l-1.8,0c-0.5,1.6-2,2.6-3.8,2.6c-2.4,0-4.2-1.8-4.2-4.4
	c0-2.5,1.8-4.4,4.2-4.4c1.8,0,3.2,1,3.8,2.6l1.8,0c-0.6-2.6-2.9-4.2-5.6-4.2c-3.4,0-5.9,2.5-5.9,6c0,3.4,2.6,6,5.9,6
	C121.7,16.3,124,14.7,124.6,12.1z M96.9,0l-1.7,0l0,16l1.7,0L96.9,0z M7.5,16.3c3.3,0,5.9-1.9,6.9-4.9l-1.9,0
	c-0.9,2-2.7,3.2-5.1,3.2c-3.3,0-5.7-2.5-5.7-5.9c0-3.4,2.4-5.9,5.7-5.9c2.3,0,4.2,1.3,5.1,3.2l1.9,0c-1-2.9-3.6-4.9-6.9-4.9
	C3.2,1.2,0,4.4,0,8.7C0,13.1,3.2,16.3,7.5,16.3z M99.4,10.3c0-3.5,2.3-6,5.4-6c2,0,3.5,1,4.4,2.6l0-2.4l1.7,0l0,11.5l-1.7,0l0-2.3
	c-0.9,1.5-2.4,2.5-4.4,2.5C101.7,16.3,99.4,13.8,99.4,10.3z M101.2,10.3c0,2.5,1.6,4.3,3.9,4.3c2.3,0,4.1-1.8,4.1-4.3
	c0-2.5-1.8-4.3-4.1-4.3C102.8,6,101.2,7.8,101.2,10.3z M126.6,10.4c0-3.5,2.4-6,5.9-6c3.6,0,5.9,2.7,5.7,6.5l-9.8,0
	c0.2,2.4,1.9,3.8,4.2,3.8c1.6,0,2.9-0.7,3.6-2l1.8,0c-1,2.4-3,3.6-5.4,3.6C129.1,16.3,126.6,13.9,126.6,10.4z M128.4,9.2l7.9,0
	c-0.4-1.9-1.8-3.2-3.9-3.2C130.3,6,128.8,7.4,128.4,9.2z M129.5,39.9c-4.5-1-5.9-1.7-5.9-3.6c0-2.5,2.1-4,5.4-4c3.3,0,5.2,1.3,6,3.4
	l2.7,0c-0.8-3.4-3.5-5.9-8.7-5.9c-4.3,0-8,2.3-8,6.5c0,4,3.2,5.1,7.9,6.2c4.3,1,6.4,1.5,6.4,3.9c0,2.6-2.1,4-5.6,4
	c-3.9,0-6-1.6-6.7-4.6l-2.7,0c0.7,3.9,3.5,7.1,9.5,7.1c4.2,0,8.2-2,8.2-6.6C138,42.1,134.9,41.1,129.5,39.9z M72.9,0l-1.7,0l0,16
	l1.7,0L72.9,0z M93.4,6.1c0,2.7-1.8,4.7-5,4.7l-4.1,0l0,5.2l-1.7,0l0-14.6l5.9,0C91.6,1.5,93.4,3.5,93.4,6.1z M91.6,6.2
	c0-1.7-1-3-3.4-3l-4.1,0l0,6.1l4.1,0C90.6,9.2,91.6,7.8,91.6,6.2z M62.9,16.3c-3.4,0-5.9-2.4-5.9-5.9c0-3.5,2.4-6,5.9-6
	c3.6,0,5.9,2.7,5.7,6.5l-9.8,0c0.2,2.4,1.9,3.8,4.2,3.8c1.6,0,2.9-0.7,3.6-2l1.8,0C67.4,15.1,65.3,16.3,62.9,16.3z M58.9,9.2l7.9,0
	C66.4,7.3,64.9,6,62.9,6C60.8,6,59.3,7.3,58.9,9.2z M82.5,41.4c0,6.5-5,11.2-11.5,11.2l-7.6,0l0-22.3l7.6,0
	C77.6,30.2,82.5,34.9,82.5,41.4z M79.9,41.4c0-5.1-3.8-8.7-8.8-8.7l-4.9,0l0,17.3l4.9,0C76.1,50,79.8,46.4,79.9,41.4z M55.7,43.6
	c0,4.7-2.6,6.8-6.1,6.8c-3.5,0-6.1-2-6.1-6.8l0-13.4l-2.7,0l0,13.5c0,6.2,3.8,9.2,8.8,9.2s8.8-3,8.8-9.2l0-13.5l-2.7,0L55.7,43.6z
	M25.2,9.1l0,6.9l1.7,0l0-7.3c0-2.8-1.5-4.4-4.1-4.4c-2,0-3.3,0.9-4.1,2.5l0-6.8L17,0l0,16l1.7,0l0-5.9C18.7,7.6,20,6,22.3,6
	C24.2,6,25.2,7,25.2,9.1z M19.8,32.7l7.2,0l0,19.8l2.7,0l0-19.8l7.2,0l0-2.5l-17.1,0L19.8,32.7z M9.2,39.9c-4.5-1-5.9-1.7-5.9-3.6
	c0-2.5,2.1-4,5.4-4c3.3,0,5.2,1.3,6,3.4l2.7,0c-0.8-3.4-3.5-5.9-8.7-5.9c-4.3,0-8,2.3-8,6.5c0,4,3.2,5.1,7.9,6.2
	c4.3,1,6.4,1.5,6.4,3.9c0,2.6-2.1,4-5.6,4c-3.9,0-6-1.6-6.7-4.6l-2.7,0c0.7,3.9,3.5,7.1,9.5,7.1c4.2,0,8.2-2,8.2-6.6
	C17.7,42.1,14.5,41.1,9.2,39.9z M117.1,41.4c0,6.6-5,11.5-11.5,11.5c-6.5,0-11.5-5-11.5-11.5c0-6.6,5-11.5,11.6-11.5
	C112.1,29.9,117.1,34.8,117.1,41.4z M114.4,41.4c0-5.2-3.9-9-8.8-9c-5,0-8.8,3.9-8.8,9c0,5.1,3.9,9,8.8,9
	C110.6,50.4,114.4,46.6,114.4,41.4z M38.8,4.6l1.7,0l0,11.5l-1.7,0l0-2.3c-0.9,1.5-2.4,2.5-4.4,2.5c-3.1,0-5.4-2.5-5.4-6
	c0-3.5,2.3-6,5.4-6c2,0,3.5,1,4.4,2.6L38.8,4.6z M38.9,10.3c0-2.5-1.8-4.3-4.1-4.3c-2.3,0-3.9,1.8-3.9,4.3c0,2.5,1.6,4.3,3.9,4.3
	C37.1,14.6,38.9,12.8,38.9,10.3z"/>
                </g>                
            </svg>
        </div>

        <header-nav class="d-none d-lg-flex" v-if="nav" :theme="theme"/>

        <header-links class="d-none d-lg-block" :contact="contact" :theme="theme"/>

        <div class="header__burger" v-on:click="openDropdown">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.29 14.38">                
                <g style="fill: none; stroke-miterlimit:10;stroke-width:2px;" :style="{'stroke': theme}">
                    <line class="cls-1" y1="1" x2="20.29" y2="1"/>
                    <line class="cls-1" y1="7.19" x2="20.29" y2="7.19"/>
                    <line class="cls-1" y1="13.38" x2="20.29" y2="13.38"/>
                </g>
            </svg>
        </div>

        
        
    </div>
</template>

<script>

import HeaderNav from './header-nav.vue';
import HeaderLinks from './header-links.vue';
import router from '../../router';

export default {
	name: "Header",
    props: {
        theme: {
            type: String,
            required: true
        },
        bg: {
            type: String,
            required: true
        },
        nav: {
            type: Boolean,
            default: true
        },
        contact: {
            type: Boolean,
            default: false
        }
    },
    data: function(){
        return{
            headerOpen: ""    
        }
    },
    components: {
        HeaderNav,
        HeaderLinks
    },
    computed: {
        isMobile: function() {
            return window.innerWidth < 992;
        }
    },
    methods:  {
        openDropdown: function() {
            this.$parent.openDropdown();
        },
        closeDropdown: function() {
            this.$parent.closeDropdown();
        },        
        goToHome: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/');
        },
        goToMotion: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/motion');
        },
        goToImage: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/image');
        },
        goToDesign: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/design');
        },
        goToAudio: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/audio');
        }
    }
};
</script>

<style lang="scss">


    .header {
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        z-index: 10;
        @media (min-width: 992px) {
            justify-content: space-between;
        }
        &__burger {
            width: 20px;
            position: fixed;
            right: 20px;
            top: 20px;
            z-index: 20;
            @media (min-width: 992px) {
                display: none;
            }
        }
        &__dropdown {
            position: fixed;
            height: 100vh;
            width: 100vw;
            top: -100vh;
            left:0;
            z-index: 25;
            transition: top .5s ease-out, background-color .5s  ease-out;
            pointer-events:none;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            &.active {
                top: 0;
                pointer-events: auto;
            }
        }
        &__close {
            width: 20px;
            position: fixed;
            right: 20px;
            top: 20px;
            z-index: 20;
            @media (min-width: 992px) {
                display: none;
            }
        }
        &__logo {
            width: 20vw;
            @media (min-width: 768px) {
                width: 140px;
            }
        }
        &__links {
            text-align:center;
            @media (min-width: 992px) {
                text-align:right;
            }
        }
        &__socials {
            margin-bottom: 10px;
        }
        &__socialitem {
            margin: 0px 10px;
            @media (min-width: 992px) {
            margin-left: 10px;
            }
            & svg {
                width: 20px;                
            }
        }
        &__nav {
            display: flex;
            text-transform: uppercase;
            @media (max-width: 991px) {
                flex-direction: column;
                align-items: center;
                margin-bottom: 40px;
                & p {
                    margin: 0;
                }
            }
        }
        &__nav-item {
            font-weight: 300;
            margin: 0px 5px;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .email {
        display: none;
        @media (min-width: 992px) {
            display: block;
            font-size:16px;
            font-weight: 200;
            text-decoration: none;
        }
    }

</style>
