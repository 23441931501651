<template>
    <div class="header__links">
        <div class="header__socials">

            <a href="https://www.linkedin.com/company/chapel-place-studios/?viewAsMember=true" target="_blank" class="header__socialitem">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.43 22.42">
                    <g :style="{'fill': theme}">
                        <path d="M20.77,0H1.66A1.63,1.63,0,0,0,0,1.62V20.81a1.63,1.63,0,0,0,1.66,1.61H20.77a1.64,1.64,0,0,0,1.66-1.61V1.62A1.64,1.64,0,0,0,20.77,0ZM6.65,19.11H3.32V8.41H6.65ZM5,6.94A1.93,1.93,0,1,1,6.92,5,1.92,1.92,0,0,1,5,6.94ZM19.11,19.11H15.79V13.9c0-1.24,0-2.83-1.73-2.83s-2,1.35-2,2.74v5.3H8.74V8.41h3.19V9.87h0a3.51,3.51,0,0,1,3.15-1.73c3.37,0,4,2.22,4,5.1Z"/>
                    </g>                    
                </svg>
            </a>
            <a href="https://www.instagram.com/chapelplacestudios/" target="_blank" class="header__socialitem">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.49 22.49">
                    <g :style="{'fill': theme}">
                        <path d="M11.25,2c3,0,3.36,0,4.54.06a6.32,6.32,0,0,1,2.09.39,3.48,3.48,0,0,1,1.29.84A3.36,3.36,0,0,1,20,4.61,6.12,6.12,0,0,1,20.4,6.7c.06,1.19.07,1.54.07,4.55s0,3.36-.07,4.54A6.07,6.07,0,0,1,20,17.88,3.72,3.72,0,0,1,17.88,20a6.07,6.07,0,0,1-2.09.39c-1.18.06-1.54.07-4.54.07s-3.36,0-4.55-.07A6.12,6.12,0,0,1,4.61,20a3.36,3.36,0,0,1-1.29-.84,3.48,3.48,0,0,1-.84-1.29,6.32,6.32,0,0,1-.39-2.09C2,14.61,2,14.25,2,11.25s0-3.36.06-4.55a6.38,6.38,0,0,1,.39-2.09,3.48,3.48,0,0,1,.84-1.29,3.48,3.48,0,0,1,1.29-.84A6.38,6.38,0,0,1,6.7,2.09C7.89,2,8.24,2,11.25,2m0-2C8.19,0,7.81,0,6.61.07A8.05,8.05,0,0,0,3.88.59a5.44,5.44,0,0,0-2,1.3,5.44,5.44,0,0,0-1.3,2A8.05,8.05,0,0,0,.07,6.61C0,7.81,0,8.19,0,11.25s0,3.43.07,4.63a8.05,8.05,0,0,0,.52,2.73,5.56,5.56,0,0,0,1.3,2,5.41,5.41,0,0,0,2,1.29,8.28,8.28,0,0,0,2.73.53c1.2.05,1.58.06,4.64.06s3.43,0,4.63-.06a8.28,8.28,0,0,0,2.73-.53,5.64,5.64,0,0,0,3.29-3.29,8.28,8.28,0,0,0,.53-2.73c.05-1.2.06-1.58.06-4.63s0-3.44-.06-4.64a8.28,8.28,0,0,0-.53-2.73,5.41,5.41,0,0,0-1.29-2,5.56,5.56,0,0,0-2-1.3A8.05,8.05,0,0,0,15.88.07C14.68,0,14.3,0,11.25,0Z"/><path class="cls-1" d="M11.25,5.47A5.78,5.78,0,1,0,17,11.25,5.78,5.78,0,0,0,11.25,5.47Zm0,9.53A3.75,3.75,0,1,1,15,11.25,3.75,3.75,0,0,1,11.25,15Z"/><circle class="cls-1" cx="17.25" cy="5.24" r="1.35"/>
                    </g>
                </svg>
            </a>
            <a href="https://www.facebook.com/Chapel-Place-Studios-105614231812439/" target="_blank" class="header__socialitem">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.56 22.56">
                    <g :style="{'fill': theme}">
                        <path d="M21.31,0H1.24A1.25,1.25,0,0,0,0,1.24V21.31a1.25,1.25,0,0,0,1.24,1.25H12.05V13.83H9.12V10.42h2.93V7.91c0-.14,0-.27,0-.4s0-.35,0-.51,0-.08,0-.12a2,2,0,0,1,0-.24,1.55,1.55,0,0,1,.05-.23c0-.15.09-.3.14-.44a.42.42,0,0,1,0-.1l.08-.2c0-.07.07-.13.1-.19a3.7,3.7,0,0,1,.75-1l.15-.13a4.47,4.47,0,0,1,2.94-1c.62,0,1.19,0,1.66,0h0l.31,0h.64V6.59H17.26a3.53,3.53,0,0,0-.48,0h-.1l-.15,0-.21.07-.08,0-.1.06L16,6.92l0,0a1.59,1.59,0,0,0-.13.13l-.09.15,0,.07a2.28,2.28,0,0,0-.16,1v2.18H19l-.44,3.41H15.57v8.73h5.74a1.25,1.25,0,0,0,1.25-1.25V1.24A1.25,1.25,0,0,0,21.31,0Z"/>
                    </g>
                </svg>
            </a>

        </div>
        <a v-if="contact" :style="{'color': theme}" class="email" href="mailto:hello@chapelplacestudios.com">hello@chapelplacestudios.com</a>
    </div>
</template>

<script>
export default {
    name: 'header-links',
    props: {
        theme: {
            type: String,
            required: true
        },
        contact: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">

</style>