<template>
    <section class="case-study">
        <div class="container">
            <div class="row">
                <div class="col text-center">
                    <h2>{{content.strap}}</h2>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="video-gallery">
                        <div class="video-gallery__item" v-for="(item, index) in content.videos" :key="index">
                            <video :src="item.url" playsinline controls :poster="require('@/images/posters/'+item.poster+'.jpg')">
                                <source :src="item.url" type="video/mp4"/>
                            </video>
                            <h3>{{item.title}}</h3>
                            <h4>{{item.brand}}</h4>
                        </div>
                    </div>
                </div>
            </div>            
            <div class="twin-col">
                <div>
                    <p>{{content.text[0]}}</p>
                </div>
                <div>
                    <p>{{content.text[1]}}</p>
                    <p style="color: #3c7c96">
                        <a :href="'mailto:' + content.contact">{{content.contact}}</a>
                    </p>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'case-study',
    props: {
        content: Object
    }
}
</script>

<style lang="scss" scoped>
    video {
        width: 100%;
    }
    a {
        font-weight: 400;
        @media (max-width: 576px) {
            font-size: 14px;
        }
    }
    .video-gallery {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        text-transform: uppercase;
        margin: 20px 0px;
        &__item {
            width: 100%;
            margin: 20px 0px;
            @media (min-width: 992px) {
                width: calc(50% - 20px);
            }
            &:only-child {
                width: 100%;    
            }
            & h3 {
                font-size: 24px;
            }
            & h4 {
                font-size: 20px;
                font-weight: 200;
            }
        }
    }
</style>