import { createWebHashHistory, createRouter } from "vue-router";
import Home from "@/views/home.vue";
import Content from "@/views/content.vue";
import * as json from "../fixtures/content.json";

const hrefPath = window.location.href.replace(window.location.origin, '');

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/motion",
        name: "Motion",
        component: Content,
        props: {
            content: json[0],
            color: "#f5ce5C",
            bg: "60,124,150",
            section: 'motion'
        }
    },
    {
        path: "/image",
        name: "Image",
        component: Content,
        props: {
            content: json[1],
            color: "#3c7c96",
            bg: "245,206,92",
            section: 'image'
        }
    },
    {
        path: "/design",
        name: "Design",
        component: Content,
        props: {
            content: json[2],
            color: "#f9d7db",
            bg: "0,153,115",
            section: 'design'
        }
    },
    {
        path: "/audio",
        name: "Audio",
        component: Content,
        props: {
            content: json[3],
            color: "#f9d7db",
            bg: "223,69,52",
            section: 'audio'
        }
    },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: Home },
];


const router = createRouter({
    history: createWebHashHistory(),
    base: hrefPath,
    routes,
});

export default router;