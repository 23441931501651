<template>
    <div :style="{'background-color': 'rgb('+bg+', 0.1)'}">
        <div class="header__dropdown" :class="headerOpen" :style="{'background-color': 'rgb('+bg+', 1)'}">
            <div class="header__close" v-on:click="closeDropdown">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.29 14.38">                
                    <g style="fill: none; stroke-miterlimit:10;stroke-width:2px;" :style="{'stroke': color}">
                        <line class="cls-1" y1="1" x2="20.29" y2="1"/>
                        <line class="cls-1" y1="7.19" x2="20.29" y2="7.19"/>
                        <line class="cls-1" y1="13.38" x2="20.29" y2="13.38"/>
                    </g>
                </svg>
            </div>

            <header-nav :theme="color"/>

            <header-links :contact="contact" :theme="color"/>

        </div>

        <section class="jumbotron" :style="{'background-color': 'rgb('+bg+', 1)'}">
            <div class="container">
                <Header :theme="color" :bg="bg"/>
                <div class="row">
                    <div class="col text-center">
                        <img class="gif" :src="require('@/images/case-study/' + section + '.svg')" alt="">
                    </div>
                </div>
            </div>
        </section>
        <case-study :content="content"/>
        <Footer />
    </div>
</template>

<script>
import Header from "../components/header/header.vue";
import HeaderNav from '../components/header/header-nav.vue';
import HeaderLinks from '../components/header/header-links.vue';
import CaseStudy from "../components/case-study.vue";
import Footer from "../components/footer.vue";
export default {

    name: 'Content',
    data: function(){
        return{
            headerOpen: ""    
        }
    },
	components: {
		Header,
		CaseStudy,
        Footer,
        HeaderNav,
        HeaderLinks
	},
    props: {
        content: Object,
        section: String,
        color: String,
        bg: String,
        video: String
    }, 
    methods: {        
        openDropdown: function() {
            this.headerOpen = 'active';
        },
        closeDropdown: function() {
            this.headerOpen = '';
        },  
    }
}
</script>

<style lang="scss" scoped>
    .jumbotron {
        padding-top: 40px;
        position: relative;
        overflow: hidden;
        z-index: 10;
    }
    .gif {
        max-width: 100%;
        position: relative;
        @media (min-width: 768px) {
            width: 100%;   

        }
    }
</style>