<template>
	<router-view />
</template>

<script>

export default {
	name: "App",
};
</script>

<style lang="scss">
	/* Global styles */
    html, :root {
        scroll-behavior: auto;
    }
	body {
		font-family: stolzl, sans-serif;
		color: #474747;
	}
    h1 {
        font-weight: 300;
        text-align: center;
        font-size: 35px;
    }
	h2 {
		font-size: 25px;
        font-weight: 300;
	}
	p {
		font-size: 19px;
		font-weight: 200;
	}
    .container {
        position: relative;
        z-index: 5;
        @media (min-width: 992px) {
            max-width: 960px;
        }
    }

    section {
		padding: 40px 20px;
        @media (min-width: 992px) {
			padding: 80px 0px;
        }
    }
    .twin-col {
        @media (min-width: 992px) {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            & > p, & > div {
                width: calc(50% - 10px);
                padding-right: 20px;
            }
        }
    }
	a {
		text-decoration: none;
		color: inherit;
		&:visited,
		&:hover,
		&:active {
			color: inherit;
		}
	}
</style>
