<template>
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col">
                    <ul class="clients">
                        <li class="clients__img" v-for="index in 31" :key="index">
                            <img :src="require('@/images/footer/client'+ index+'.svg')" alt="">
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style lang="scss" scoped>

    footer.footer {
        background: white;
		padding: 40px 20px;
        @media (min-width: 992px) {
			padding: 80px;
        }
    }
    .clients {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style-type: none;
        flex-wrap: wrap;
        padding:0;
        &__img {
            width: calc(25% - 0px);
            @media (min-width: 992px) {
                width: calc(25% - 20px);
            }
            height: 50px;
            margin: 20px 0;
            display: flex;
            align-items: center;
            justify-content: center;

            & img {
                max-width: 50%;
                max-height: 80%;
            }
        }
    }

</style>