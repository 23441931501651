<template>
    <nav  class="header__nav" :style="{'color': theme}">

        <p v-on:click="goToHome" class="header__nav-item">Home</p>
        <p>&bull;</p>
        <p v-on:click="goToMotion" class="header__nav-item">Motion</p>
        <p>&bull;</p>
        <p v-on:click="goToImage" class="header__nav-item">Image</p>
        <p>&bull;</p>
        <p v-on:click="goToDesign" class="header__nav-item">Design</p>
        <p>&bull;</p>
        <p v-on:click="goToAudio" class="header__nav-item">Audio</p>

    </nav>
</template>

<script>
import router from '../../router';
export default {
    name: 'header-nav',
    props: {
        theme: {
            type: String,
            required: true
        }
    },
    methods:  {
        goToHome: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/');
            this.$parent.closeDropdown();
        },
        goToMotion: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/motion');
            this.$parent.closeDropdown();
        },
        goToImage: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/image');
            this.$parent.closeDropdown();
        },
        goToDesign: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/design');
            this.$parent.closeDropdown();
        },
        goToAudio: function() {
            document.querySelector("html").scrollTop = 0
            router.push('/audio');
            this.$parent.closeDropdown();
        }
    }
}
</script>

<style lang="scss">

</style>